export default defineNuxtRouteMiddleware(() => {
    const { useAuth } = useAuthEndpoints();
    const { session } = useAuth();

    const jwtToken = session.value?.accessToken;

    // If the user is not authenticated and trying to access a non-login route
    if (!jwtToken) {
        return navigateTo('/login');
    }

    return true;
});
